import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import colors from "../../constants/colors";
import SearchInventory from "./searchInventoryTypes";
import SearchIngredientsByLot from "./searchIngredientsByLot";
import { getIngredientLotsByName } from "../../actions/submissions";

class LotCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initial,
      dialogVisible: false,
      height: 30,
      reset: false,
      ingredientOptions: []
    };
  }
  componentDidMount(){
    const { showInventorySearch, isRecipe, components, position, submission, defaultLabel, section_id, showWipSearch, isInvoice, isInventoryTransfer} = this.props;
    if((showInventorySearch || showWipSearch) && (isRecipe || isInvoice || isInventoryTransfer)){
      var sectionComponent = components.find((c, idx) => (c?.type == "section") && (section_id == c.section_id));
      var ingredientComponent = components.filter((c, idx) => (c?.defaultLabel == "ingredient" || c?.defaultLabel == "name" || c?.defaultLabel == "item" || c?.defaultLabel == "itemName") && (section_id == c.section_id));
        var ingredientName = "";
        if(ingredientComponent?.length > 0){
          var ingredientComponentId = ingredientComponent[0].id;
          if(submission[ingredientComponentId]){
            if(submission[ingredientComponentId][position]){
              ingredientName = submission[ingredientComponentId][position];
              if(ingredientName){
                var data = {
                  name: ingredientName, 
                  type: (ingredientComponent[0]?.defaultLabel == "ingredient" || ingredientComponent[0]?.defaultLabel == "itemName") ? 
                            showWipSearch ?
                             "wip" : "ingredient" : (isInventoryTransfer && ingredientComponent[0]?.defaultLabel == "item") ? 
                              sectionComponent?.defaultLabel == "finishedGoods" ? 
                              "fg" : sectionComponent?.defaultLabel == "workInProgress" ? 
                                "wip" : sectionComponent?.defaultLabel == "packagingMaterials" ?
                                  "packagingMaterial" : "ingredient" : isInvoice ? 
                                      "fg" : "packagingMaterial"
                }
                this.props.getIngredientLotsByName(data, (res)=>{
                  if(res?.lots?.length){
                    this.setState({ ingredientOptions: res.lots});
                  }
                });
              }
            }
          }
        }
    }
  }

  componentWillReceiveProps(nextProps){
    const { showInventorySearch, isRecipe, components, position, defaultLabel, section_id, showWipSearch, isInvoice, isInventoryTransfer } = this.props;
    const { submission } = nextProps;
    if(nextProps.initial != this.props.initial){
      this.setState({value: nextProps.initial});
    }
    if(nextProps?.resetInventorySearch){
      if((nextProps?.resetInventorySearch?.changed != this.props.resetInventorySearch?.changed && nextProps?.resetInventorySearch?.position == this.props?.position && this.props?.isShipmentLog && (this.props?.defaultLabel == 'lotcode_finished' || this.props?.defaultLabel == 'lotcode_wip'))){
        this.setState({ value: nextProps.initial, reset: !this.state.reset });
      }
    }
    //Show lots for item added in recipes
    if((showInventorySearch || showWipSearch) && (isRecipe || isInvoice || isInventoryTransfer)){
      var sectionComponent = components.find((c, idx) => (c?.type == "section") && (section_id == c.section_id));
      var ingredientComponent = components.filter((c, idx) => (c?.defaultLabel == "ingredient" || c?.defaultLabel == "name" || c?.defaultLabel == "item" || c?.defaultLabel == "itemName") && (section_id == c.section_id));
        var ingredientName = "";
        if(ingredientComponent?.length > 0){
          var ingredientComponentId = ingredientComponent[0].id;
          if(submission[ingredientComponentId]){
            if(submission[ingredientComponentId][position]){
              ingredientName = submission[ingredientComponentId][position];
              if(ingredientName){
                var data = {
                  name: ingredientName, 
                  type: (ingredientComponent[0]?.defaultLabel == "ingredient" || ingredientComponent[0]?.defaultLabel == "itemName") ? 
                            showWipSearch ?
                             "wip" : "ingredient" : (isInventoryTransfer && ingredientComponent[0]?.defaultLabel == "item") ? 
                              sectionComponent?.defaultLabel == "finishedGoods" ? 
                              "fg" : sectionComponent?.defaultLabel == "workInProgress" ? 
                                "wip" : sectionComponent?.defaultLabel == "packagingMaterials" ?
                                  "packagingMaterial" : "ingredient" : isInvoice ? 
                                      "fg" : "packagingMaterial"
                }
                this.props.getIngredientLotsByName(data, (res)=>{
                  if(res?.lots?.length){
                    this.setState({ ingredientOptions: res.lots});
                  }
                });
              }
            }
          }
        }
    }
  }
  generateLotCode(max,min){
    const {id, submissionSelected, onChange, lotConsecutive} = this.props;
    var val = submissionSelected?._id ? submissionSelected._id.slice(-8) + lotConsecutive : Math.floor(Math.random() * (max - min) + min);
    onChange(id, val);
    this.setState({ value: val });
  }
  onSelectItem(val){
    const { onChange, id } = this.props;
    
    this.setState({value: val});
    onChange(id, val);
  }
  render() {
    const { value, reset, ingredientOptions } = this.state;
    const { id, onChange, label,customlot, isIssuable, onSubmitIssue, isCompleted, submissionSelected, showLotGenerator, width, openIssue, defaultComponent, showInventorySearch, defaultLabel, isShipmentLog, options, submission, showWipSearch, isRecipe, isInvoice, isInventoryTransfer} = this.props;
    const type = defaultLabel == "lotcode_finished" ? "Finished goods" : defaultLabel == "lotcode_wip" ? "Work in progress" : showWipSearch ? "Work in progress" : "";
    return (
      <>  
      { !width ?
          <NewIssue  
            visible={this.state.dialogVisible} 
            id={id} 
            label={label} 
            onSubmitIssue={onSubmitIssue} 
            handleClose={()=>this.setState({dialogVisible:false})} 
            width={width}
          /> : null
        }

      <View style={styles.container, {
        paddingTop: width ? 0 : 5
      }}>
        {!customlot?
          <Text style={styles.label}>{label}</Text>:null
        }
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            if(width){
              openIssue();
            }else{
              this.setState({dialogVisible:true});
            }
          }}>
            <Text style={{color:colors.primary, fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }
        {
          ((showInventorySearch || (isShipmentLog && defaultLabel == "lotcode")) && options?.length > 0 && isShipmentLog) &&
            <SearchInventory defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} type={type} searchBy={'lot'} isShipmentLog={isShipmentLog} options={options} defaultLabel={defaultLabel} reset={reset} id={id} submission={submission}/>
        }
        {
          (showInventorySearch && (!isShipmentLog && !isRecipe && !isInvoice && !isInventoryTransfer)) &&
            <SearchInventory defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} type={type} searchBy={'lot'} isShipmentLog={isShipmentLog} options={options} defaultLabel={defaultLabel} reset={reset} id={id} submission={submission}/>
        }
        {/* //For recipes */}
        {
          (showInventorySearch && (ingredientOptions?.length > 0 || typeof value == "object") && (isRecipe || isInvoice || isInventoryTransfer)) &&
            <SearchIngredientsByLot defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} type={type} searchBy={'lot'} isRecipe={true} isInvoice={isInvoice} isInventoryTransfer={isInventoryTransfer} options={ingredientOptions} defaultLabel={defaultLabel} reset={reset} id={id} submission={submission}/>
        }
        {/* {
          showWipSearch && 
          <SearchIngredientsByLot defaultComponent={defaultComponent} onSelectItem={(val) => this.onSelectItem(val)} initial={value} type={type} searchBy={'lot'} isRecipe={isRecipe} options={ingredientOptions} defaultLabel={defaultLabel} reset={reset} id={id} submission={submission}/>
          // <SearchInventory defaultComponent={defaultComponent} onSelectItem={(val) => {if(val?.lot) this.onSelectItem(val.lot); else this.onSelectItem(val); }} initial={value} type={type} searchBy={'lot'} isShipmentLog={isShipmentLog} options={options} defaultLabel={defaultLabel} reset={reset} id={id} submission={submission} showWipSearch={showWipSearch}/>
        } */}
        {
            (!(showInventorySearch && (!isShipmentLog && !isRecipe)) && 
             !((showInventorySearch || (isShipmentLog && defaultLabel == "lotcode")) && options?.length > 0 && isShipmentLog) &&
             !(showInventorySearch && (ingredientOptions?.length > 0 || typeof value == "object") && (isRecipe || isInvoice || isInventoryTransfer)) &&
             !(showWipSearch && (ingredientOptions?.length > 0 || typeof value == "object") && isRecipe)) &&
              <TextInput
                value={value}
                editable={!defaultComponent}
                onContentSizeChange={(event) => {
                    if(event.nativeEvent.contentSize.height == 0){
                      if(this.props.placeholder == ''){
                        this.setState({height: 30})
                      }
                      this.setState({height: event.nativeEvent.contentSize.height});
                    }else{
                      setTimeout(() => {
                        this.setState({height: event.nativeEvent.contentSize.height});
                      }, 5)
                    }
                  }}
                  style={{ 
                  borderColor: "#e7e7e7",
                  borderRadius: 5,
                  borderWidth: 1,
                  color: "#424242",
                  fontFamily: "Roboto",
                  fontSize: 20,
                  fontWeight: "400",
                  padding: 5, 
                  height: this.state.height,
                  fontStyle: this.state.value == "" || !this.state.value ? 'italic' : 'normal'
                }}
                multiline={true}
                placeholder={this.props.placeholder ? this.props.placeholder : ""}
                placeholderTextColor={'rgb(0, 47, 108)'}
                onChangeText={(val) => {
                  if(val == '' && this.props.placeholder == ''){
                    this.setState({ value: val, height: 30 });
                  
                  }else{
                    this.setState({ value: val });
                  
                  }
                  onChange(id, val);
                }}
                
              />
        }
        {
          showLotGenerator &&
            <TouchableOpacity style={styles.btnLotCode} onPress={() => { this.generateLotCode(999999999999,10000000)}}>
              <Text style={styles.txtLotCode}>Generate Lot Code</Text>  
            </TouchableOpacity>
        }
      </View>
      </>
    );
  }
}
export default connect((state, ownProps = {}) => ({
}), (dispatch, props) => ({
  getIngredientLotsByName: (data, callback) => {
    dispatch(getIngredientLotsByName(data, callback));
  },
  dispatch: dispatch,
}))(LotCode);

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    alignItems: "stretch",
    flexDirection: "column",
    // paddingLeft: 10,
    // paddingRight: 10,
    paddingTop: 5,
    // paddingBottom: 10,
  },
  input: {
    fontSize: 22,
    color: "#424242",
    fontWeight: "400",
    alignSelf: "stretch",
    height: 50,
    flex: 1,
    borderStyle: "solid",
    borderColor: "#e7e7e7",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    fontFamily: "Roboto",
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
  btnLotCode:{
    padding: 10,
    textAlign: "center",
    marginTop: 10,
    borderRadius: 5,
    backgroundColor: colors.primary
  },
  txtLotCode:{
    color: 'white',
    fontSize: 18
  }
});
