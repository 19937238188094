import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import { searchPackagingMaterials } from "../../actions/records";

class SearchPackaging extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.initial ? props.initial : "",
            height: 30,
            items: [],
            wasEdited: false
        };
    }
    searchItems(val) {
        const { onSelectItem } = this.props;
        if(val)
            this.props.searchPackagingMaterials({ page: 1, name: val }, (res) => {
                if(res?.packagingMaterials?.length > 0){
                    this.setState({ items: res?.packagingMaterials });
                }else{
                    this.setState({ items: [] });
                    onSelectItem(val);
                }
            });
        else this.setState({ items: [] });
    }
    render() {
        const { value, items, wasEdited } = this.state;
        const { defaultComponent, onSelectItem } = this.props;

        return <View>
            <TextInput
                value={value}
                editable={!defaultComponent}
                onContentSizeChange={(event) => {
                    if (event.nativeEvent.contentSize.height == 0) {
                        if (this.props.placeholder == '') {
                            this.setState({ height: 30 })
                        }
                        this.setState({ height: event.nativeEvent.contentSize.height });
                    } else {
                        setTimeout(() => {
                            this.setState({ height: event.nativeEvent.contentSize.height });
                        }, 5)
                    }
                }}
                style={styles.input, {
                    borderColor: "#e7e7e7",
                    borderRadius: 5,
                    borderWidth: 1,
                    color: "#424242",
                    fontFamily: "Roboto",
                    fontSize: 20,
                    fontWeight: "400",
                    padding: 5,
                    height: this.state.height,
                    fontStyle: this.state.value ? this.state.value.length == 0 ? 'italic' : 'normal' : 'normal',
                    overflow: 'visible',
                }}
                multiline={true}
                placeholder={"Enter text to search packaging materials"}
                placeholderTextColor={'rgb(0, 47, 108)'}
                onChangeText={(val) => {
                    if (val == '' && this.props.placeholder == '') {
                        this.setState({ value: val, height: 30, wasEdited: true  });

                    } else {
                        this.setState({ value: val, wasEdited: true  });
                    }
                    this.searchItems(val);

                }}
            />
            {
                items?.length > 0 &&
                    items.map(item =>{
                        return <div style={styles.row}>
                            <div>
                                {item?.namePackaging}
                            </div>
                            <div>
                                <button style={styles.rowBtn} onClick={() => {
                                    onSelectItem(item?.namePackaging)
                                    this.setState({ items: [], value: item?.namePackaging, wasEdited: false  });
                                }}>+</button>
                            </div>
                            
                        </div>
                    })
            }
            {
                value && items?.length <= 0 && wasEdited &&  <View>
                    <Text>No packaging material was found</Text>
                </View>
            }
        </View>
    }
}

export default connect((state, ownProps = {}) => ({
    isLoading: state.forms.getIn(["form", "status"]) === "loading",
}), (dispatch, props) => ({
    searchPackagingMaterials: (data, callback) =>
        dispatch(searchPackagingMaterials(data, callback)),
}))(SearchPackaging);

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        paddingTop: 5,
        flex: 1,
    },
    input: {
        borderColor: "#e7e7e7",
        borderRadius: 5,
        borderWidth: 1,
        color: "#424242",
        fontFamily: "Roboto",
        fontSize: 22,
        fontWeight: "400",
        paddingLeft: 10,
    },
    label: {
        fontSize: 20,
        color: '#939598',
        fontWeight: '300',
        fontFamily: 'Roboto',

    },
    row:{
        display: "grid",
        width: "100%",
        gridTemplateColumns: "90% 10%",
        fontFamily: "Roboto",
        fontSize: 18
    },
    rowBtn:{
        color: 'rgb(244, 152, 30)',
        border: '2px solid',
        borderRadius: 4
    }
});