import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export default class DropDown extends Component {
  state = {
    isVisible: false,
    selected: null,
  };
  componentWillReceiveProps(nextProps){
    const { options } = this.props;
    if(nextProps.updated != this.props.updated){
      var opt = options.filter(option => option.id == nextProps.updated);
      if(opt.length > 0){
        this.setState({ selected: opt[0] });
      }
    }
    if (nextProps.openList != this.props.openList) {
      this.setState({ isVisible: nextProps.openList });
    }
  }

  render() {
    const { isVisible, selected } = this.state;
    const { options, initialOption, onSelect, defaultComponent } = this.props;
    const selectedOption = selected ? selected.value : initialOption.value;
    return (
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            if(!defaultComponent){
              this.setState({ isVisible: !isVisible })
            }
          }}
        >
          <Text style={styles.label}>{selectedOption}</Text>
          <MaterialCommunityIcons
            name="chevron-down"
            size={30}
            color="#9B9B9B"
            style={styles.icon}
          />
        </TouchableOpacity>
        {isVisible ? (
          <View style={styles.options}>
            <ScrollView>
              {options.map((option, idx) => (
                <TouchableOpacity
                  style={styles.item}
                  key={idx}
                  onPress={() => {
                      onSelect(option);
                      this.setState({ selected: option, isVisible: !isVisible });
                  }}
                >
                  <Text style={styles.label}>{option.value}</Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    borderWidth: 1,
    borderColor: "#e7e7e7",
    borderRadius: 5,
    paddingLeft: 10,
    height: 60,
  },
  label: {
    fontSize: 16,
    color: "#333",
    fontWeight: "200",
    fontFamily: "Roboto",
    wordBreak: 'break-all'
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 5,
  },
  options: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e7e7e7",
    borderRadius: 5,
    borderTopLeftRadius: 0,
    paddingBottom: 5,
  },
  item: {
    paddingLeft: 10,
    paddingVertical: 10,
  },
});
