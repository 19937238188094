import React, { Component } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, TouchableHighlight, TextInput, Modal, ScrollView } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import * as DocumentPicker from 'expo-document-picker';
import colors from '../../constants/colors';
// import Modal from 'react-native-modalbox';
import { api } from "../../api";
import { setAttachment } from "../../actions/submissions";
import Swal from "sweetalert2";
import "../styles.css";
import { viewContainer } from "./../functions";
import { Table, Row } from 'react-native-table-component';
import { editDefaultData } from '../../actions/menu';
import FormComponent from '../component';
import moment from "moment-timezone";
import { GOOGLE_DOCS } from "@env";
import { BRANDING } from "@env";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      pin: '',
      validPin: 1234,
      isValidPin: false,
      showIncorrectPin: false,
      documentName: '',
      showInstructions: false,
      note: '',
      show: false,
      components: this.props.children,
      docHeight: 600,
      showPinInput: false,
      width: window.innerWidth,
      defaultData: props.defaultData,
      defaultSubmission: this.getInitialSub(),
      rerenderTable: false,
      toEdit: '',
      dataType: this.props.section?.dataType ? this.props.section?.dataType : ''
    }
  }
  setInitialState(){
    this.setState({
      modalVisible: false,
      pin: '',
      validPin: 1234,
      isValidPin: false,
      showIncorrectPin: false,
      documentName: '',
      showInstructions: false,
      note: '',
      show: false,
      components: this.props.children,
      docHeight: 600,
      showPinInput: false,
      width: window.innerWidth,
      defaultData: this.props.defaultData,
      defaultSubmission: this.getInitialSub(),
      rerenderTable: false,
      toEdit: '',
      dataType: this.props.section?.dataType ? this.props.section?.dataType : ''
    });
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  }
  getInitialSub() {
    var submission = this.props.section?.defaultSubmission ? this.props.section?.defaultSubmission : {};
    var newSub = {};
    if (Object.entries(submission).length > 0) {
      Object.entries(submission).map(submit => {
        if (submission[submit[0]]) {
          var valArray = [];
          newSub[submit[0]] = [];
          submit[1].map((s, i) => {
            var val = {
              value: s,
              changed: false
            };
            valArray.push(val);
          })
          newSub[submit[0]] = valArray;

        }
      });

    }
    return newSub;

  }

  componentDidMount() {
    let windowHeight = window.innerHeight;
    let newDocHeight = (windowHeight * 0.60)
    this.setState({ isValidPin: false, showInstructions: false, docHeight: newDocHeight })
    if (this.props.attachment_type == 'note') {
      this.setState({ showInstructions: true })
    }
    this.getDocSize()
    window.addEventListener('resize', this.handleResize);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.pin != this.state.pin ||
      nextState.modalVisible != this.state.modalVisible ||
      nextState.isValidPin != this.state.isValidPin ||
      nextState.showIncorrectPin != this.state.showIncorrectPin ||
      nextState.documentName != this.state.documentName ||
      nextState.showInstructions != this.state.showInstructions ||
      nextState.note != this.state.note ||
      nextState.show != this.state.show ||
      nextState.components != this.state.components ||
      nextState.showPinInput != this.state.showPinInput ||
      nextState.defaultData != this.state.defaultData ||
      nextState.width != this.state.width ||
      nextState.docHeight != this.state.docHeight ||
      this.state.rerenderTable !== nextState.rerenderTable ||
      nextState.toEdit != this.state.toEdit ||
      nextProps.rerenderTable != this.props.rerenderTable ||
      nextState.dataType != this.state.dataType ||
      nextProps.palletComponentChanged != this.props.palletComponentChanged ||
      nextProps.itemComponentChanged != this.props.itemComponentChanged ||
      nextProps.choicelist != this.props.choicelist
    );

  }
  componentWillUnmount() {
    // window.removeEventListener('resize', () => {
    // })
    window.removeEventListener('resize', this.handleResize);
  }
  getDocSize() {
    window.addEventListener("resize", () => {
      let windowHeight = window.innerHeight;
      let newDocHeight = (windowHeight * 0.50)
      this.setState({ docHeight: newDocHeight })
    });
  }

  uploadDocument(uri, name) {
    this.updateDocument(uri, name);
    return;
  }

  updateDocument(uri, name) {
    const { id, onChange, formId, setFormAttachment, mediaId } = this.props;
    let data = 'document';
    const dividedName = name?.split(".");
    const extFile = dividedName[dividedName.length - 1];
    if (extFile != "doc") {
      setFormAttachment({
        data,
        uri,
        name,
        formId,
        attachment_type: 'file',
        componentId: id,
        mediaId,
      }, () => this.setInitialState())
    }
    else
      Swal.fire({
        text: `Please upload the document using the latest word format (.docx) .`,
        confirmButtonText: "Close",
        confirmButtonColor: colors.orange,
        backdrop: false

      });
  }

  pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync();

    if (result.type == "success") {
      if (result.size > 30000000) {
        this.props.setBigFileIsLoading("loading_big_file");
      }
      this.setState({ documentName: result.name })
      this.uploadDocument(result.uri, result.name);
    }
  }

  pickNote = (note) => {
    this.setState({ show: !this.state.show });

  }


  attachmentViewer = (media) => {

    const mediaName = typeof media !== 'undefined' ? media.split("/")[4] : '';
    let mediaExt = mediaName !== '' ? mediaName.split(".")[1] : 'none';
    mediaExt = mediaExt ? mediaExt.toLowerCase() : mediaExt;
    let windowHeight = window.innerHeight;
    let newDocHeight = (windowHeight * 0.60)

    let mediaUrl = '';
    switch (mediaExt) {
      case 'docx':
      case 'xlsx':
      case 'xls':
      case 'ppt':
      case 'pptx':
        mediaUrl = `${GOOGLE_DOCS}${api + media}&embedded=true`;
        return (
          <iframe
            style={{ justifyContent: 'center', height: newDocHeight, width: 700, alignSelf: 'center', borderColor: '#ddd', borderRadius: 5 }}

            src={mediaUrl}>
          </iframe>
        );
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        mediaUrl = `${api + media}`;
        return (
          <div style={{ justifyContent: 'center', maxWidth: 500, maxHeight: newDocHeight, alignSelf: 'center', borderRadius: 15, overflow: 'auto' }}>
            <img src={mediaUrl} style={{ maxWidth: 500, maxHeight: newDocHeight }} />
          </div>
        );
      case 'mov':
      case 'm4v':
      case 'mkv':
      case 'avi':
      case 'mpeg4':
      case 'webm':
      case 'mp3':
      case 'mp4':
        mediaUrl = `${api + media}`;
        return (
          <div style={{ justifyContent: 'center', maxWidth: 700, maxHeight: newDocHeight, alignSelf: 'center', borderRadius: 15, overflow: 'auto' }}>
            {
              this.state.modalVisible ?
                <video autoPlay id='video' controls src={mediaUrl} style={{ maxWidth: 700, maxHeight: newDocHeight, }} />
                : null
            }
          </div>
        );
      default:
        mediaUrl = `${api + media}`;
        return (
          <iframe
            style={{ justifyContent: 'center', height: this.state.docHeight, width: 700, alignSelf: 'center', borderColor: '#ddd', borderRadius: 5 }}
            src={mediaUrl}>
          </iframe>
        );
    }
  }

  saveDefault() {
    const { onSaveDefault, components, section } = this.props;
    const { defaultSubmission, defaultData, dataType } = this.state;
    var submitSend = {};
    components.filter(component => component.section_id == section?.section_id && component.type != 'section').map((component, idx) => {
      submitSend[component.id] = [];
      if (defaultSubmission[component.id]) {
        defaultSubmission[component.id].map((sub, idx) => {
          submitSend[component.id][idx] = sub.value;
        });
      }

    });

    this.props.editDefaultData({
      id: this.props.formId,
      component_id: this.props.section?.id,
      defaultData: defaultData,
      defaultSubmission: submitSend ? submitSend : {},
      dataType: dataType
    }, () => {
      onSaveDefault(defaultData, defaultSubmission, dataType);
      this.setInitialState();
    })
  }

  modal() {
    const { modalVisible, pin, isValidPin, validPin, showIncorrectPin, documentName, showInstructions, showPinInput, show, note, defaultData, defaultSubmission, toEdit } = this.state;
    const { label, media, formId, section, components, onSaveDefault } = this.props;
    let showContent = false;
    if (this.props.attachment_type == 'note' || this.props.attachment_type == 'file' && typeof media !== 'undefined') {
      showContent = !showContent
    }
    // if (Platform.OS == "web") {
    return (
      <Modal
        visible={modalVisible}
        transparent
      >
        <View style={{ backgroundColor: '#f2f2f2', height: '100%' }}>
          <View style={{
            flexDirection: "row",
            backgroundColor: '#f2f2f2',
          }}>
            <View style={{ flex: 1, }} />
            <View style={viewContainer(this.state.width, styles)} >
              {
                !toEdit && section?.direction == "table" ?
                  <View style={{ position: 'relative' }}>
                    <TouchableHighlight
                      style={{
                        position: 'absolute',
                        top: -54,
                        right: -44,
                        borderRadius: 20,
                        padding: 10,
                        zIndex: 10,
                      }}
                      activeOpacity={0.5}
                      onPress={() => this.setState({ modalVisible: false })}
                    >
                      <Text style={{
                        color: colors.closeText,
                        fontSize: 20,
                        fontWeight: 'bold',
                        textAlign: 'center',

                      }}><Icon name="close" style={{ fontSize: 40, color: colors.close }} /></Text>
                    </TouchableHighlight>
                    {/* Preview Wizard Files */}
                    {
                      showContent && this.props.attachment_type == 'note' ?
                        <View style={{
                          justifyContent: 'center',
                          backgroundColor: '#f2f2f2',
                        }}>
                          <Text style={styles.textNote}>{media}</Text>
                        </View>
                        : null
                    }
                    {
                      showContent && this.props.attachment_type == 'file' ?
                        <>
                          {this.attachmentViewer(media)}
                        </> :
                        null
                    }
                    <Text style={{ fontSize: 18, fontWeight: 500 }}>Select one to edit</Text>
                    <TouchableHighlight
                      style={{...styles.button, backgroundColor: colors.edit, borderColor: colors.edit}}
                      // onPress={() => {
                      //   this.setState({ toEdit: 'wizard' })
                      // }}
                      onPress={() => {
                        this.setState({ showPinInput: true, toEdit: 'wizard' })
                      }}
                    >
                      <Text style={styles.textButton}>Edit Wizard</Text>
                    </TouchableHighlight>
                    <TouchableHighlight
                      style={{...styles.button, backgroundColor: colors.edit, borderColor: colors.edit}}
                      // onPress={() => {
                      //   this.setState({ toEdit: 'table' })
                      // }}
                      onPress={() => {
                        this.setState({ showPinInput: true, toEdit: 'table' })
                      }}
                    >
                      <Text style={styles.textButton}>Edit Table</Text>
                    </TouchableHighlight>
                  </View>
                  :
                  <View style={{ position: 'relative' }}>
                    <TouchableHighlight
                      style={{
                        position: 'absolute',
                        top: -44,
                        right: -44,
                        backgroundColor: '#fff',
                        borderRadius: 20,
                        padding: 10,
                        zIndex: 10,
                      }}
                      activeOpacity={0.5}
                      onPress={() => this.setState({ modalVisible: false })}
                    >
                      <Text style={{
                        color: colors.closeText,
                        fontSize: 20,
                        fontWeight: 'bold',
                        textAlign: 'center',

                      }}><Icon name="close" style={{ fontSize: 40, color: colors.close }} /></Text>
                    </TouchableHighlight>

                    <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{label}</Text>
                    {/* Preview Wizard Files */}
                    {
                      section?.direction == "table" ?
                        toEdit == "wizard" ?
                          showContent && this.props.attachment_type == 'note' ?
                            <View style={{
                              justifyContent: 'center',
                              backgroundColor: '#f2f2f2',
                            }}>
                              <Text style={styles.textNote}>{media}</Text>
                            </View>
                            : null
                          : null
                        :
                        showContent && this.props.attachment_type == 'note' ?
                          <View style={{
                            justifyContent: 'center',
                            backgroundColor: '#f2f2f2',
                          }}>
                            <Text style={styles.textNote}>{media}</Text>
                          </View>
                          : null
                    }
                    {
                      section?.direction == "table" ?
                        toEdit == "wizard" ?
                          showContent && this.props.attachment_type == 'file' ?
                            <>
                              {this.attachmentViewer(media)}
                            </> :
                            null
                          :
                          null
                        :
                        showContent && this.props.attachment_type == 'file' ?
                          <>
                            {this.attachmentViewer(media)}
                          </> : null
                    }
                    {
                      section?.direction == "table" ?
                        toEdit == "wizard" ?
                          documentName != "" &&
                          <Text style={{ fontSize: 15, flex: 0.2 }}>Media to be uploaded: {documentName}</Text>
                          : null
                        :
                        documentName != "" &&
                        <Text style={{ fontSize: 15, flex: 0.2 }}>Media to be uploaded: {documentName}</Text>
                    }
                    {/* Preview table */}
                    {
                      (!isValidPin && section?.direction == "table" && toEdit == "table") &&
                      <div>
                        <div style={{ overflow: 'scroll' }}>
                          <View style={{ marginTop: 10, maxHeight: 500 }}>
                            <Table style={{ justifyContent: "flex-start" }} borderStyle={{ borderWidth: 2, borderColor: '#fff', justifyContent: "flex-start" }}>
                              <Row data={this.getTableHeaders(components, section?.section_id)} widthArr={this.getCellArrayWidth(components, section?.section_id)} style={styles.head} textStyle={{ ...styles.text, color: 'white' }} />
                              {
                                this.renderTableBody(isValidPin)
                              }
                            </Table>
                          </View>
                        </div>
                      </div>
                    }
                    {
                      !isValidPin &&
                      <>

                        <Modal
                          visible={showPinInput}
                          style={styles.modalPin}
                          transparent
                        >
                          <View style={styles.modalPin}>
                            {
                              showIncorrectPin &&
                              <Text style={{ fontSize: 15, flex: 0.2, color: colors.error }}>Incorrect PIN, please verify it and try again</Text>
                            }
                            <TextInput
                              secureTextEntry={true}
                              value={pin}
                              style={styles.input}
                              placeholder={"Security PIN"}
                              onChangeText={(val) => {
                                this.setState({ pin: val });
                              }}
                            />
                            <TouchableHighlight
                              style={{...styles.button, backgroundColor: colors.validate, borderColor: colors.validate}}
                              onPress={() => {
                                if (pin == validPin) {
                                  this.setState({ isValidPin: true, showIncorrectPin: false });
                                }
                                else {
                                  this.setState({ showIncorrectPin: true });
                                }



                              }}
                            >
                              <Text style={styles.textButton}>Validate PIN</Text>
                            </TouchableHighlight>

                            <TouchableHighlight
                              style={{...styles.button, backgroundColor: colors.closeWizard, borderColor: colors.closeWizard}}
                              onPress={() => {
                                this.setState({ showPinInput: false })
                              }}
                            >
                              <Text style={styles.textButton}>Close</Text>
                            </TouchableHighlight>
                          </View>

                        </Modal>



                        <TouchableHighlight
                          style={{...styles.button, backgroundColor: colors.edit, borderColor: colors.edit}}
                          onPress={() => {
                            this.setState({ showPinInput: true })
                          }}
                        >
                          <Text style={styles.textButton}>Edit Instructions</Text>
                        </TouchableHighlight>
                      </>
                    }
                    {
                      section?.direction == "table" ?
                        toEdit == "wizard" ?
                          <Modal
                            visible={show}
                            style={styles.modalNote}
                            transparent
                          >
                            <View style={styles.modalNote}>

                              <TextInput
                                value={note}
                                style={styles.inputNote}
                                placeholder={"Enter a note"}
                                onChangeText={(val) => {
                                  this.setState({ note: val });
                                }}
                              />
                              <TouchableHighlight
                                style={{...styles.button, backgroundColor: colors.saveWizard, borderColor: colors.saveWizard}}
                                onPress={() => {
                                  const { id, formId, setFormAttachment, mediaId } = this.props;
                                  setFormAttachment({
                                    val: this.state.note,
                                    formId,
                                    media: this.state.note,
                                    attachment_type: 'note',
                                    componentId: id,
                                    mediaId,
                                  }, () => this.setInitialState())
                                }}
                              >
                                <Text style={styles.textButton}>Save Note</Text>
                              </TouchableHighlight>

                              <TouchableHighlight
                                style={{...styles.button, backgroundColor: colors.closeWizard, borderColor: colors.closeWizard}}
                                onPress={() => {
                                  this.setState({ show: false })
                                }}
                              >
                                <Text style={styles.textButton}>Cancel</Text>
                              </TouchableHighlight>
                            </View>
                          </Modal>
                          : null
                        : <Modal
                          visible={show}
                          style={styles.modalNote}
                          transparent
                        >
                          <View style={styles.modalNote}>

                            <TextInput
                              value={note}
                              style={styles.inputNote}
                              placeholder={"Enter a note"}
                              onChangeText={(val) => {
                                this.setState({ note: val });
                              }}
                            />
                            <TouchableHighlight
                              style={{...styles.button, backgroundColor: colors.saveWizard, borderColor: colors.saveWizard}}
                              onPress={() => {
                                const { id, formId, setFormAttachment, mediaId } = this.props;
                                setFormAttachment({
                                  val: this.state.note,
                                  formId,
                                  media: this.state.note,
                                  attachment_type: 'note',
                                  componentId: id,
                                  mediaId,
                                }, () => this.setInitialState())
                              }}
                            >
                              <Text style={styles.textButton}>Save Note</Text>
                            </TouchableHighlight>

                            <TouchableHighlight
                              style={{...styles.button, backgroundColor: colors.closeWizard, borderColor: colors.closeWizard}}
                              onPress={() => {
                                this.setState({ show: false })
                              }}
                            >
                              <Text style={styles.textButton}>Cancel</Text>
                            </TouchableHighlight>
                          </View>
                        </Modal>
                    }


                    {
                      isValidPin &&
                      <>
                        {
                          section?.direction == "table" ?
                            toEdit == "wizard" ?
                              <>
                                <TouchableHighlight
                                  style={{...styles.button, backgroundColor: colors.send, borderColor: colors.send}}
                                  onPress={this.pickDocument}
                                >
                                  <Text style={styles.textButton}>Add Document +</Text>
                                </TouchableHighlight>
                                <TouchableHighlight
                                  style={{...styles.button, backgroundColor: BRANDING == "gohaccp" ? colors.orange : "white", borderColor: BRANDING == "gohaccp" ? colors.orange : colors.send }}
                                  onPress={this.pickNote}
                                >
                                  <Text style={{...styles.textButton, color: BRANDING == "gohaccp" ? "white" : colors.send}}>Add a note +</Text>
                                </TouchableHighlight>
                                {
                                  media && 
                                    <TouchableHighlight
                                    style={{...styles.button, backgroundColor: colors.remove, borderColor: colors.remove}}
                                      onPress={() => this.removeWizard()}
                                    >
                                      <Text style={styles.textButton}>Remove -</Text>
                                    </TouchableHighlight>
                                }
                                
                              </>
                              : null
                            : <>
                              <TouchableHighlight
                                style={{...styles.button, backgroundColor: colors.send, borderColor: colors.send}}
                                onPress={this.pickDocument}
                              >
                                <Text style={styles.textButton}>Add Document +</Text>
                              </TouchableHighlight>
                              <TouchableHighlight
                                style={{...styles.button, backgroundColor: BRANDING == "gohaccp" ? colors.orange : "white", borderColor: BRANDING == "gohaccp" ? colors.orange : colors.send }}
                                onPress={this.pickNote}
                              >
                                <Text style={{...styles.textButton, color: BRANDING == "gohaccp" ? "white" : colors.send}}>Add a note +</Text>
                              </TouchableHighlight>
                              {
                                media && 
                                  <TouchableHighlight
                                    style={{...styles.button, backgroundColor: colors.remove, borderColor: colors.remove}}
                                    onPress={() => this.removeWizard()}
                                  >
                                    <Text style={styles.textButton}>Remove -</Text>
                                  </TouchableHighlight>
                              }
                            </>
                        }


                        {
                          (section?.direction == "table" && toEdit == "table") &&
                          <>
                            <div>
                            </div>
                            <div style={{ overflow: 'scroll' }}>
                              <View style={{ marginTop: 10, maxHeight: 500 }}>
                                <Table style={{ justifyContent: "flex-start" }} borderStyle={{ borderWidth: 2, borderColor: '#fff', justifyContent: "flex-start" }}>
                                  <Row data={this.getTableHeaders(components, section?.section_id)} widthArr={this.getCellArrayWidth(components, section?.section_id)} style={styles.head} textStyle={{ ...styles.text, color: 'white' }} />
                                  {
                                    this.renderTableBody(isValidPin)
                                  }


                                </Table>
                              </View>

                            </div>
                            <View style={styles.buttonsContainer}>
                              <TouchableOpacity style={styles.btnAdd}
                                onPress={() => {
                                  this.addRow();
                                }}
                              >
                                <Text>
                                  <Icon name="plus-circle-outline" style={{ fontSize: 30, }} />
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity style={styles.btnAdd}
                                onPress={() => {
                                  if (defaultData > 1) {
                                    this.setState({ defaultData: defaultData - 1, rerenderTable: !this.state.rerenderTable });
                                  }
                                }}
                              >
                                <Text>
                                  <Icon name="close-circle-outline" style={{ fontSize: 30, }} />
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </>

                        }
                        {
                          (section?.direction == "table" && toEdit == "table") &&
                          <TouchableHighlight
                            style={{...styles.button, backgroundColor: colors.saveWizard, borderColor: colors.saveWizard}}
                            onPress={() => {
                              this.saveDefault();
                            }}
                          >
                            <Text style={styles.textButton}>Save Table</Text>
                          </TouchableHighlight>
                        }
                      </>
                    }


                  </View>

              }

            </View>
            <View style={{ flex: 1 }}>

            </View>
          </View>
        </View>
      </Modal>);

  }

  getCellArrayWidth(components, section_id) {
    var widthArray = [];
    var w = 150;
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component, idx) => {
      switch (component?.width) {
        case 'mini':
          w = 134;
          break;
        case 'small':
          w = 200;
          break;
        case 'medium':
          w = 300;
          break;
        case 'large':
          w = 480;
          break;
        default:
          w = 200;
          break;
      }
      widthArray.push(w);
    })
    return widthArray;
  }
  getTableHeaders(components, section_id) {
    var labels = [];
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component) => {
      labels.push(component.label);
    })

    return labels;
  }
  onComponentChange(id, val, subtype, position) {
    const { defaultSubmission } = this.state;
    var submit = {};
    if (Object.entries(defaultSubmission).length > 0) {
      submit = defaultSubmission;
      if (submit[id]?.length) {
        if (submit[id][position]) {
          submit[id][position].value = val;
          submit[id][position].changed = true;
        } else {
          submit[id][position] = {};
          submit[id][position].value = val;
          submit[id][position].changed = true;
        }
      } else {
        submit[id] = [];
        submit[id][position] = {};
        submit[id][position].value = val;
        submit[id][position].changed = true;
      }
    } else {
      submit[id] = [];
      var value = {
        value: val,
        changed: true
      };
      submit[id][position] = value;
    }
    this.setState({ defaultSubmission: submit });
  }
  addRow() {
    const { defaultSubmission } = this.state;
    const { components, section, companyTimezone, choicelist } = this.props;
    var defaultSubmit = defaultSubmission;
    if (Object.entries(defaultSubmission).length > 0) {
      components.filter(component => component.section_id == section?.section_id && component.type != 'section').map((component) => {
        var newSub = defaultSubmission[component.id] ? defaultSubmission[component.id] : [];
        const date = moment().format("YYYY-MM-DD HH:mm");
        const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");

        switch (component.type) {
          case "datetime":
            newSub.push({ value: initialDateTime, changed: false });
            break;
          case "dropdown":
            newSub.push({ value: choicelist[component.object_type_id][0]["id"], changed: false });
            break;
          case
            "signature",
            "timer",
            "timerauto",
            "document":
            newSub.push({ value: 0, changed: false });
            break;
          case "yesno",
            "yesnoall":
            newSub.push({ value: 0, changed: false });
            break;
          default:
            newSub.push({ value: "", changed: false });
            break;
        }
        defaultSubmit[component.id] = newSub;
      });
    }
    this.setState({ defaultSubmission: defaultSubmit, defaultData: this.state.defaultData + 1, rerenderTable: !this.state.rerenderTable });
  }

  getTableBody(components, section_id, position) {
    const { choicelist, checkboxkey, companyTimezone } = this.props;
    const { defaultSubmission, isValidPin } = this.state;
    var cells = [];
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component) => {
      let new_label;
      switch (component.type) {
        case "customlotcode":
          new_label = `${component.label} - ${component.lotcodetypename}`;
          break;
        case "inventory":
          new_label = `${component.label} - ${component.inventorytypename} Code`;
          break;
        default:
          new_label = `${component.label}`;
      }
      cells.push(<View>
        <FormComponent
          id={component.id}
          type={component.type}
          key={component.id}
          label={component.type == "inventory" ? new_label : ''}
          initial={
            defaultSubmission
              ? defaultSubmission[component.id]
                ? defaultSubmission[component.id][position]
                  ? defaultSubmission[component.id][position]?.value ? defaultSubmission[component.id][position]?.value
                    : ''
                  : ''
                : ''
              : defaultSubmission[component.id]
                ? defaultSubmission[component.id] : ''}
          placeholder={component.placeholder ? component.placeholder : ""}
          componentLink={""}
          enabledLink={false}
          onChange={(id, value, subtype, position) => {
            this.onComponentChange(id, value, subtype, position);
          }}
          options={choicelist[component.object_type_id]}
          checkboxkey={checkboxkey}
          status={false}
          submissionSelected={null}
          showLotGenerator={null}
          lotConsecutive={null}
          customlot={false}
          isIssuable={false}
          onSubmitIssue={(issue) => { }}
          openIssue={() => { }}
          position={position}
          width={component?.width ? component?.width : "small"}
          defaultComponent={!isValidPin}
          companyTimezone={companyTimezone}
          secondDatePicker={component.type == "datetime" ? true : false}
        />
      </View>);
    })

    return cells;
  }
  renderTableBody() {
    const { components, section } = this.props;
    const { defaultData, defaultSubmission } = this.state;
    var rowsNumber = defaultData - 1;
    var rows = [];
    for (var i = 0; i <= rowsNumber; i++) {
      rows.push(<Row data={this.getTableBody(components, section?.section_id, i)} widthArr={this.getCellArrayWidth(components, section?.section_id)} style={i % 2 == 0 ? { backgroundColor: '#EDEEEE', justifyContent: "flex-start" } : { backgroundColor: 'white', justifyContent: "flex-start" }} textStyle={{ ...styles.text, color: 'white' }} />)
    }

    return rows;

  }
  removeWizard(){
    const { id, formId, setFormAttachment, mediaId } = this.props;
    const { modalVisible } = this.state;
    setFormAttachment({
      val: "",
      formId,
      media: "",
      attachment_type: "",
      componentId: id,
      mediaId,
    }, () => this.setInitialState());
  }

  render() {
    const { label, showSeparator, children, isLast, media, formSubmission, defaultData, section, components, direction, status } = this.props;
    const { defaultSubmission } = this.state;
    const iconColor = media ? colors.wizard : 'gray';
    return <View style={isLast ? styles.containerLast : styles.container}>

      {this.modal()}
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', wordBreak: 'break-word', wordWrap: 'break-word', }}>
        <Text style={showSeparator ? styles.labelFirst : styles.label}>{label}</Text>

        {formSubmission ?
          null
          :
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <TouchableOpacity
              disabled={status == "archived" || status == "approved"}
              onPress={() => {
                this.setState({ modalVisible: true })
              }} >
              <Icon name="help-box" style={{ color: iconColor, fontSize: 30, }} />
            </TouchableOpacity>
          </View>}


      </View>
      {children}
    </View>;
  }
}

export default connect(
  (state) => {
    return {
      isLoading: state.forms.getIn(["form", "status"]) === "loading",
      companyTimezone: state.user.get("companyTimezone"),
    }
  },
  (dispatch, props) => {
    return {
      setFormAttachment: (params, callback) => dispatch(setAttachment(params, callback)),
      editDefaultData: (data, callback) => dispatch(editDefaultData(data, callback))
    };
  }
)(Section);

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.orange,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.orange,
    padding: 10,
    marginTop: 10,
    width: '100%'

  },
  textButton: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  input: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    padding: 10,
    margin: 10,
    fontSize: 18,
    color: colors.primary,
  },
  inputNote: {
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    padding: 10,
    margin: 10,
    fontSize: 18,
    color: colors.primary,
    width: '100%'
  },
  textNote: {
    fontSize: 18,
    color: colors.primary,
    margin: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 5,
    textAlign: 'center',

  }, labelFirst: {
    fontSize: 20,
    color: colors.primary,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  label: {
    fontSize: 20,
    color: colors.primary,
    marginTop: 10,
    marginBottom: 10,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
  },
  container: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    borderBottomWidth: 10,
    borderBottomColor: "#F0F0F0",
  },
  containerLast: {
    alignSelf: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  modalPin: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: '#eee',
    borderRadius: 10,
    borderWidth: 1,
    justifyContent: 'center',
    height: 300,
    margin: 'auto',
    padding: 30,
    width: 300
  },
  modalNote: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: '#eee',
    borderRadius: 10,
    borderWidth: 1,
    justifyContent: 'center',
    height: 300,
    margin: 'auto',
    padding: 30,
    width: 500
  },
  viewContainerMax: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 10,
    minWidth: 600,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  viewContainerMax1: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 10,
    minWidth: 350,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    marginBottom: 10,
    minWidth: 300,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  head: {
    backgroundColor: colors.orange,
    color: 'white'
  },
  text: {
    margin: 6
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 10,
  },
  defaultBtn: {
    alignItems: "center",
    backgroundColor: colors.userCommand,
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    height: 23,
    marginTop: 5
  },
  buttonText: {
    color: colors.userCommandText,
    fontSize: 14,
    fontFamily: "Roboto",
  },
  select: {
    fontSize: 16,
    fontWeight: 500,
    padding: 5,
    paddingLeft: 2,
    fontFamily: 'Roboto',
    borderRadius: 4
  }


});
