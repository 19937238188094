import { uploadMedia } from "../../../api";
import { BASE_API } from "@env";

export const getOpenAIContent = async (question, imageUploadEnabled, imagePickerResult, authToken) => {
  const cleanedQuestion = question.replace("@assistant ", "");
  if (imageUploadEnabled && imagePickerResult) {
    const imageData = imagePickerResult?.assets?.at(0)?.uri;
    const resp = await uploadMedia(null, null, null, imageData, "imagestes.jpg", authToken);
    return [
      {"type": "text", "text": cleanedQuestion},
      {"type": "image_url", "image_url": {"url": `${BASE_API}${resp?.data?.path}`}}
    ];
  }
  return cleanedQuestion;
};