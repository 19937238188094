import React, { useCallback } from 'react'
import {
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import colors from "../constants/colors";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { FlatList } from 'react-native-web';

export const RecordPaginator = ({
  currentPage,
  pageSize,
  navigationHandler,
  tasksCount,
  filter,
  resultsPerPage = 10,
}) => {

  const nPages = tasksCount % resultsPerPage === 0 ? Math.trunc(tasksCount / pageSize) : Math.trunc(tasksCount / pageSize) + 1;
  const pagesArray = Array.from({ length: nPages }, (_, i) => i + 1);
  const onPressHandler = useCallback((page) => navigationHandler({ page, ...(filter != 'all' && { filter }) }), [navigationHandler, filter]);

  return <View style={{ flex: 1, flexDirection: 'row', backgroundColor: colors.primary, padding: 10, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
    {currentPage != 1 &&
      <TouchableOpacity onPress={() => onPressHandler(currentPage - 1)} >
        <Icon name="menu-left-outline" style={{ color: '#fff', fontSize: 38 }} />
      </TouchableOpacity>}
    <FlatList
      data={pagesArray}
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      renderItem={({ item, index }) => (
        <TouchableOpacity key={`indxNum_${index}`} onPress={() => onPressHandler(item)}>
          <Text style={{
            color: '#fff',
            fontSize: 18,
            marginHorizontal: 5,
            fontWeight: item === currentPage ? 'bold' : 'normal'
          }}>
            {item}
          </Text>
        </TouchableOpacity>
      )}
      keyExtractor={(_, index) => index.toString()}
      horizontal={true}
    />
    {currentPage != nPages &&
      <TouchableOpacity onPress={() => onPressHandler(currentPage + 1)} >
        <Icon name="menu-right-outline" style={{ color: '#fff', fontSize: 38 }} />
      </TouchableOpacity>}
  </View>
};